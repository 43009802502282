<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <div slot="header">
          <h5 class="title">
            100 Awesome Nucleo Icons
          </h5>
          <p class="category">
            Handcrafted by our friends from
            <a href="https://nucleoapp.com/?ref=1712">NucleoApp</a>
          </p>
        </div>
        <div class="all-icons">
          <div class="row">
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons arrows-1_cloud-download-93"
                />
                <p>arrows-1_cloud-download-93</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons arrows-1_cloud-upload-94"
                />
                <p>arrows-1_cloud-upload-94</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons arrows-1_minimal-down"
                />
                <p>arrows-1_minimal-down</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons arrows-1_minimal-left"
                />
                <p>arrows-1_minimal-left</p>
              </div>
            </div>

            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons arrows-1_minimal-right"
                />
                <p>arrows-1_minimal-right</p>
              </div>
            </div>

            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons arrows-1_minimal-up" />
                <p>arrows-1_minimal-up</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons arrows-1_refresh-69" />
                <p>arrows-1_refresh-69</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons arrows-1_share-66 " />
                <p>arrows-1_share-66</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons business_badge" />
                <p>business_badge</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons business_bank" />
                <p>business_bank</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons business_briefcase-24"
                />
                <p>business_briefcase-24</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons business_bulb-63" />
                <p>business_bulb-63</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons business_chart-bar-32"
                />
                <p>business_chart-bar-32</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons business_chart-pie-36"
                />
                <p>business_chart-pie-36</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons business_globe" />
                <p>business_globe</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons business_money-coins"
                />
                <p>business_money-coins</p>
              </div>
            </div>

            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons clothes_tie-bow" />
                <p>clothes_tie-bow</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons design_app" />
                <p>design_app</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons design_bullet-list-67"
                />
                <p>design_bullet-list-67</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons design_image" />
                <p>design_image</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons design_palette" />
                <p>design_palette</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons design_scissors" />
                <p>design_scissors</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons design_vector" />
                <p>design_vector</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons design-2_html5" />
                <p>design-2_html5</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons design-2_ruler-pencil"
                />
                <p>design-2_ruler-pencil</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons emoticons_satisfied" />
                <p>emoticons_satisfied</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons files_box" />
                <p>files_box</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons files_paper" />
                <p>files_paper</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons files_single-copy-04"
                />
                <p>files_single-copy-04</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons health_ambulance" />
                <p>health_ambulance</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons loader_gear" />
                <p>loader_gear</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons loader_refresh" />
                <p>loader_refresh</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons location_bookmark" />
                <p>location_bookmark</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons location_compass-05" />
                <p>location_compass-05</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons location_map-big" />
                <p>location_map-big</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons location_pin" />
                <p>location_pin</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons location_world" />
                <p>location_world</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons media-1_album" />
                <p>media-1_album</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons media-1_button-pause"
                />
                <p>media-1_button-pause</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons media-1_button-play" />
                <p>media-1_button-play</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons media-1_button-power"
                />
                <p>media-1_button-power</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons media-1_camera-compact"
                />
                <p>media-1_camera-compact</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons media-2_note-03" />
                <p>media-2_note-03</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons media-2_sound-wave" />
                <p>media-2_sound-wave</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons objects_diamond" />
                <p>objects_diamond</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons objects_globe" />
                <p>objects_globe</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons objects_key-25" />
                <p>objects_key-25</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons objects_planet" />
                <p>objects_planet</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons objects_spaceship" />
                <p>objects_spaceship</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons objects_support-17" />
                <p>objects_support-17</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons objects_umbrella-13" />
                <p>objects_umbrella-13</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons education_agenda-bookmark"
                />
                <p>education_agenda-bookmark</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons education_atom" />
                <p>education_atom</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons education_glasses" />
                <p>education_glasses</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons education_hat" />
                <p>education_hat</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons education_paper" />
                <p>education_paper</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons shopping_bag-16" />
                <p>shopping_bag-16</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons shopping_basket" />
                <p>shopping_basket</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons shopping_box" />
                <p>shopping_box</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons shopping_cart-simple"
                />
                <p>shopping_cart-simple</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons shopping_credit-card"
                />
                <p>shopping_credit-card</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons shopping_delivery-fast"
                />
                <p>shopping_delivery-fast</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons shopping_shop" />
                <p>shopping_shop</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons shopping_tag-content"
                />
                <p>shopping_tag-content</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons sport_trophy" />
                <p>sport_trophy</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons sport_user-run" />
                <p>sport_user-run</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons tech_controller-modern"
                />
                <p>tech_controller-modern</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons tech_headphones" />
                <p>tech_headphones</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons tech_laptop" />
                <p>tech_laptop</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons tech_mobile" />
                <p>tech_mobile</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons tech_tablet" />
                <p>tech_tablet</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons tech_tv" />
                <p>tech_tv</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons tech_watch-time" />
                <p>tech_watch-time</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons text_align-center" />
                <p>text_align-center</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons text_align-left" />
                <p>text_align-left</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons text_bold" />
                <p>text_bold</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons text_caps-small" />
                <p>text_caps-small</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons gestures_tap-01" />
                <p>gestures_tap-01</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons transportation_air-baloon"
                />
                <p>transportation_air-baloon</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons transportation_bus-front-12"
                />
                <p>transportation_bus-front-12</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons travel_info" />
                <p>travel_info</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons travel_istanbul" />
                <p>travel_istanbul</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-1_bell-53" />
                <p>ui-1_bell-53</p>
              </div>
            </div>

            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-1_check" />
                <p>ui-1_check</p>
              </div>
            </div>

            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-1_calendar-60" />
                <p>ui-1_calendar-60</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons ui-1_lock-circle-open"
                />
                <p>ui-1_lock-circle-open</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-1_send" />
                <p>ui-1_send</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i
                  class="now-ui-icons ui-1_settings-gear-63"
                />
                <p>ui-1_settings-gear-63</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-1_simple-add" />
                <p>ui-1_simple-add</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-1_simple-delete" />
                <p>ui-1_simple-delete</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-1_simple-remove" />
                <p>ui-1_simple-remove</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-1_zoom-bold" />
                <p>ui-1_zoom-bold</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-2_chat-round" />
                <p>ui-2_chat-round</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-2_favourite-28" />
                <p>ui-2_favourite-28</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-2_like" />
                <p>ui-2_like</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-2_settings-90" />
                <p>ui-2_settings-90</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-2_time-alarm" />
                <p>ui-2_time-alarm</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons ui-1_email-85" />
                <p>ui-1_email-85</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons users_circle-08" />
                <p>users_circle-08</p>
              </div>
            </div>
            <div
              class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
            >
              <div class="font-icon-detail">
                <i class="now-ui-icons users_single-02" />
                <p>users_single-02</p>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
